.App {
  text-align: center;
  
}

* {
  margin: 0 auto;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  margin-bottom: -100;
}

span {
  padding-left: 25px;
  padding-right: 25px;
}

.word {
  font-size: 4rem;
  font-weight: 500;
}

.definition {
  font-size: 1.5rem;
}

.tag {
  padding-top: 60px;
  color: white;
  font-size: 6px;
}

.twitter-icon, .github-icon, .dev-icon {
  color: #303F9F;
  padding-top: 20px;
  padding-bottom: 20px;
}
