body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  
}

* {
  margin: 0 auto;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  margin-bottom: -100;
}

span {
  padding-left: 25px;
  padding-right: 25px;
}

.word {
  font-size: 4rem;
  font-weight: 500;
}

.definition {
  font-size: 1.5rem;
}

.tag {
  padding-top: 60px;
  color: white;
  font-size: 6px;
}

.twitter-icon, .github-icon, .dev-icon {
  color: #303F9F;
  padding-top: 20px;
  padding-bottom: 20px;
}

